export default[ 
    {
      path: '/setup_kategori_titik_rawan',
      name: '/setup_kategori_titik_rawan',
      component: () => import('@/views/setup/kategori_titik_rawan/index.vue'),
      meta: {
        pageTitle: 'Setup ',
        breadcrumb: [ 
          {
            text: 'Setup',
            to : '/setup_kategori_titik_rawan'
          },
          {
            text: 'Kategori Titik Rawan', 
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/setup_kategori_pengaduan',
      name: '/setup_kategori_pengaduan',
      component: () => import('@/views/setup/kategori_pengaduan/index.vue'),
      meta: {
        pageTitle: 'Setup ',
        breadcrumb: [ 
          {
            text: 'Setup',
            to : '/setup_kategori_pengaduan'
          },
          {
            text: 'Kategori Pengaduan', 
            active: true,
          },
        ],
      },
    }, 
       
]