export default [
    {
        path: '/titik_rawan_kategori',
        name: '/titik_rawan_kategori',
        component: () => import('@/views/titik_rawan/kategori/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Titik Rawan',
                    to: '/titik_rawan_kategori'
                },
                {
                    text: 'Setup Kategori',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/titik_rawan_input',
        name: '/titik_rawan_input',
        component: () => import('@/views/titik_rawan/input/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Titik Rawan',
                    to: '/titik_rawan_input'
                },
                {
                    text: 'Input ',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/titik_rawan_rekap',
        name: '/titik_rawan_rekap',
        component: () => import('@/views/titik_rawan/rekap/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Titik Rawan',
                    to: '/titik_rawan_rekap'
                },
                {
                    text: 'Rekap Sebaran Titik Rawan ',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/titik_rawan_gis',
        name: '/titik_rawan_gis',
        component: () => import('@/views/titik_rawan/gis/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Titik Rawan',
                    to: '/titik_rawan_rekap'
                },
                {
                    text: 'GIS Sebaran Titik Rawan ',
                    active: true,
                },
            ],
        },
    },



]