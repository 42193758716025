export default [
    {
        path: '/laporan',
        name: '/laporan',
        component: () => import('@/views/laporan/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan Pengaduan Masyarakat',
                    to: '/laporan'
                },  
            ],
        },
    }, 
    

]