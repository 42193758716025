export default [
    {
        path: '/pengaduan',
        name: '/pengaduan',
        component: () => import('@/views/pengaduan/index.vue'),
        meta: {
            pageTitle: 'pengaduan ',
            breadcrumb: [{
                    text: 'Pengaduan',
                    to: '/pengaduan'
                },  
            ],
        },
    }, 

    {
        path: '/pengaduan_kategori',
        name: '/pengaduan_kategori',
        component: () => import('@/views/pengaduan/kategori/index.vue'),
        meta: {
            pageTitle: 'pengaduan ',
            breadcrumb: [{
                    text: 'Pengaduan',
                    to: '/pengaduan_kategori'
                },  
                {
                    text: 'Kategori ',
                    to: '/pengaduan_kategori',
                    active : true
                },  
            ],
        },
    }, 

    {
        path: '/pengaduan_daftar',
        name: '/pengaduan_daftar',
        component: () => import('@/views/pengaduan/daftar/index.vue'),
        meta: {
            pageTitle: 'pengaduan ',
            breadcrumb: [{
                    text: 'Pengaduan',
                    to: '/pengaduan_daftar'
                },  {
                    text: 'Daftar Aduan Masyarakat ',
                    to: '/pengaduan_daftar',
                    active : true
                },
            ],
        },
    }, 
    {
        path: '/pengaduan_laporan',
        name: '/pengaduan_laporan',
        component: () => import('@/views/pengaduan/laporan/index.vue'),
        meta: {
            pageTitle: 'pengaduan ',
            breadcrumb: [{
                    text: 'Pengaduan',
                    to: '/pengaduan'
                },  {
                    text: 'Laporaan ',
                    to: '/pengaduan_laporan',
                    active : true
                },  
            ],
        },
    }, 
    

]