export default [{
        path: '/daftar_pengaduan',
        name: '/daftar_pengaduan',
        component: () => import('@/views/frond/daftar_pengaduan/index.vue'),
        meta: {
            layout: 'full', 
        },
    },

    {
        path: '/app',
        name: '/app',
        component: () => import('@/views/frond/app/index.vue'),
        meta: {
            layout: 'full',
        },
    }, 
    {
        path: '/gis',
        name: '/gis',
        component: () => import('@/views/frond/gis_rawan_kecelakaan/index.vue'),
        meta: {
            layout: 'full', 
        },
    },
    {
        path: '/m_apps',
        name: 'm_apps',
        component: () => import('@/views/frond/home/main_app.vue'),
        meta: {
            layout: 'full', 
        },
        children:[
            {
                path: '/app_home',
                name: 'app_home',
                component: () => import('@/views/frond/home/index.vue'),
                meta: {
                    layout: 'full', 
                },
            },
            {
                path: '/app_gis',
                name: 'app_gis',
                component: () => import('@/views/frond/gis_rawan_kecelakaan/index.vue'),
                meta: {
                    layout: 'full', 
                },
            },
            {
                path: '/app_daftar_pengaduan',
                name: 'app_daftar_pengaduan',
                component: () => import('@/views/frond/daftar_pengaduan/index.vue'),
                meta: {
                    layout: 'full', 
                },
            },
        ]
        
    },

]