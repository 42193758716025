export default [
    {
        path: '/pengaduan_masyarakat',
        name: '/pengaduan_masyarakat',
        component: () => import('@/views/pengaduan_masyarakat/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Pengajuan Pengaduan Masyarakat',
                    to: '/pengaduan_masyarakat'
                },  
            ],
        },
    }, 
     

]