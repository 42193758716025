import Vue from 'vue'
import VueRouter from 'vue-router'
import acl from './routers/acl'
import error from './routers/error'
import home from './routers/home'
import profile from './routers/profile' 
import setup from './modul/setup' 
import laporan from './modul/laporan' 
import pengaduan from './modul/pengaduan'
import titik_rawan from './modul/titik_rawan'
import pelopor_keselamatan from './modul/pelopor_keselamatan'
import user_pelapor from './modul/user_pelapor'
import input_user from './modul/input_user'
import frond from './modul/frond'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', redirect: { name: 'login' } },
    ...acl,
    ...error,
    ...home,
    ...profile,  
    ...setup,  
    ...laporan, 
    ...pengaduan,
    ...titik_rawan,
    ...pelopor_keselamatan,
    ...user_pelapor,
    ...input_user, 
    ...frond
  ],
}) 
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})



export default router
