export default [
    {
        path: '/pelopor_keselamatan_kategori',
        name: '/pelopor_keselamatan_kategori',
        component: () => import('@/views/pelopor_keselamatan/kategori/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Pelopor Keselamatan',
                    to: '/pelopor_keselamatan_kategori'
                },  
                {
                    text: 'Setup Kategori',
                    active: true,
                },
            ],
        },
    }, 
    {
        path: '/pelopor_periode_pendaftaran',
        name: '/pelopor_periode_pendaftaran',
        component: () => import('@/views/pelopor_keselamatan/periode_pendaftaran/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Pelopor Keselamatan',
                    to: '/pelopor_periode_pendaftaran'
                },  
                {
                    text: 'Setup Periode Pendaftaran',
                    active: true,
                },
            ],
        },
    }, 
    {
        path: '/pelopor_keselamatan_pendaftar',
        name: '/pelopor_keselamatan_pendaftar',
        component: () => import('@/views/pelopor_keselamatan/pendaftar/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Pelopor Keselamatan',
                    to: '/pelopor_keselamatan_pendaftar'
                },  
                {
                    text: 'Daftar Pendaftar',
                    active: true,
                },
            ],
        },
    }, 
    {
        path: '/pendaftaran_pelopor_keselamatan',
        name: '/pendaftaran_pelopor_keselamatan',
        component: () => import('@/views/pelopor_keselamatan/pendaftaran/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Pelopor Keselamatan',
                    to: '/pendaftaran_pelopor_keselamatan'
                },  
                {
                    text: 'Pendaftaran',
                    active: true,
                },
            ],
        },
    }, 

]