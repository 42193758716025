export default [
    {
        path: '/user_pelapor',
        name: '/user_pelapor',
        component: () => import('@/views/user_pelapor/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'User',
                    to: '/user_pelapor'
                },
                {
                    text: 'Daftar User Pelapor',
                    active: true,
                },
            ],
        },
    },
]